import { Backend, IRawRequestListResponse, IResource } from '@/models'

export const ServiceChannelEpgs = new (class ServiceChannelEpgs extends Backend {
    async fetchChannelEpgs(): Promise<IResource[]> {
        const { data } = await this.get<IRawRequestListResponse<IResource>>(
            `technician/television/epg/channels`,
            this.createRequestConfig({ cacheStrategy: 'static' })
        )

        return data.results
    }

    async fetchChannelEpg(id: string): Promise<IResource> {
        const epgs = await this.fetchChannelEpgs()
        const epg = epgs.find((epg) => epg.id === id)

        if (!epg) throw Error(`EPG with id ${id} does not exist`)

        return epg
    }
})()
