import decodeJWT from 'jwt-decode'
import { IUserAccount, IUserAccountTokens, IUserDetails } from '@/models'
import { ITokenData } from './ITokenData'

export class UserAccount implements IUserAccount {
    readonly id: string
    readonly name: string
    readonly accessToken: string
    readonly refreshToken: string
    readonly accessTokenExpirationTimestampMs: number
    readonly details: IUserDetails

    constructor({ accessToken, refreshToken }: IUserAccountTokens, userDetails: IUserDetails) {
        const { user_id, username, exp } = decodeJWT<ITokenData>(accessToken)

        this.id = user_id
        this.name = username
        this.accessToken = accessToken
        this.refreshToken = refreshToken
        this.accessTokenExpirationTimestampMs = exp * 1e3
        this.details = userDetails
    }
}
