import { App, inject, reactive } from 'vue'
import { ILocales } from '@/intl/models'
import { localesPl, localesDe, localesEnUs } from '@/intl'
import { LANGUAGE } from '@/constants'
import { getStorageOrBrowserOrFallbackLocale } from '@/utils'

export interface Intl {
    locale: `${LANGUAGE}`
    locales: {
        [key in `${LANGUAGE}`]: ILocales
    }
    t(): ILocales
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $intl: Intl
    }
}

const intlLibrary: Intl = {
    locale: getStorageOrBrowserOrFallbackLocale(),
    locales: {
        [LANGUAGE.PL]: localesPl,
        [LANGUAGE.EN_US]: localesEnUs,
        [LANGUAGE.DE]: localesDe,
    },
    t() {
        return this.locales[this.locale]
    },
}

export const intlSymbol = Symbol('intl')

export const intl = (Vue: App) => {
    const intl = reactive(intlLibrary)
    Vue.config.globalProperties.$intl = intl
    Vue.provide(intlSymbol, intl)
}

export function useIntl() {
    const intlInstance = inject<Intl>(intlSymbol)
    if (!intlInstance) throw new Error('No intl provided!')

    return intlInstance
}
