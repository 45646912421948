import * as Sentry from '@sentry/vue'
import { App } from 'vue'
import { Router } from 'vue-router'

export const sentry = (Vue: App, router: Router) => {
    const dsn = import.meta.env.VITE_APP_SENTRY_DSN

    if (dsn) {
        Sentry.init({
            Vue,
            dsn,
            environment: import.meta.env.VITE_APP_SENTRY_ENV,
            integrations: [
                Sentry.browserTracingIntegration({
                    router,
                    tracingOrigins: ['*'],
                }),
                Sentry.replayIntegration(),
            ],
            tracesSampleRate: 0.2,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        })
    }
}
