import { App, inject } from 'vue'
import { BackendPush } from '@/models'

export const pushApiSymbol = Symbol('pushApi')

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $pushApi: BackendPush
    }
}

export const pushApi = {
    install(Vue: App) {
        const backendPush = new BackendPush()
        Vue.config.globalProperties.$pushApi = backendPush
        Vue.provide(pushApiSymbol, backendPush)
    },
}

export function usePushApi() {
    const instance = inject<BackendPush>(pushApiSymbol)
    if (!instance) throw new Error('No push API provided!')

    return instance
}
