import { App, inject } from 'vue'
import { ServiceToasts } from '@/services'

export type Toasts = typeof ServiceToasts
export const toastsSymbol = Symbol('toasts')

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $toasts: Toasts
    }
}

export const toasts = {
    install(Vue: App) {
        Vue.config.globalProperties.$toasts = ServiceToasts
        Vue.provide(toastsSymbol, ServiceToasts)
    },
}

export function useToasts() {
    const toastsInstance = inject<Toasts>(toastsSymbol)
    if (!toastsInstance) throw new Error('No toasts provided!')

    return toastsInstance
}
