import { DateTime } from 'luxon'
import { IChannel, IChannelConfig } from '@/models'
import { IRawChannel, IRawChannelConfig } from '../models'
import { ServiceChannelGroups } from '../ServiceChannelGroups'
import { ServiceChannelEpgs } from '../ServiceChannelEpgs'

export class ConverterChannel {
    constructor() {
        throw new Error('Cannot instantiate using a static class')
    }

    static async fromRaw({
        rf_broadcast_type: broadcastType,
        program_number: programNumber,
        timestamp_created_at,
        channels_group,
        epg_channel_id,
        ...data
    }: IRawChannel): Promise<IChannel> {
        const [channelsGroup, epg] = await Promise.all([
            channels_group && ServiceChannelGroups.fetchChannelGroup(channels_group),
            epg_channel_id && ServiceChannelEpgs.fetchChannelEpg(epg_channel_id),
        ])

        return {
            broadcastType,
            programNumber,
            ...(channelsGroup && { channelsGroup }),
            ...(epg && { epg }),
            timestampCreatedAt: DateTime.fromISO(timestamp_created_at),
            ...data,
        }
    }

    static toRaw({
        broadcastType,
        programNumber,
        channelsGroup,
        epg,
        ...data
    }: Partial<IChannelConfig>): Partial<IRawChannelConfig> {
        return {
            rf_broadcast_type: broadcastType,
            program_number: programNumber,
            channels_group: channelsGroup?.id,
            epg_channel_id: epg?.id,
            ...data,
        }
    }
}
