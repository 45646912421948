export enum ROUTE {
    START = 'Start',
    SIGN_IN = 'SignIn',
    PASSWORD_CHANGE = 'PasswordChange',

    DEVICE_LIST = 'DeviceList',
    DEVICE_DETAILS = 'DeviceDetails',
    DEVICE_DETAILS_DETAILS = 'DeviceDetailsDetails',
    DEVICE_DETAILS_CHANNELS_HISTORY = 'DeviceDetailsChannelsHistory',
    DEVICE_REGISTER = 'DeviceRegister',
    HOTEL_MODE = 'HotelMode',
    HOTEL_MODE_EDIT = 'HotelModeEdit',
    HOTEL_MODE_CREATE = 'HotelModeCreate',
    DEVICE_EDIT = 'DeviceEdit',
    MY_ACCOUNT = 'MyAccount',
    CHANNELS = 'Channels',
    CHANNEL_LIST = 'ChannelList',
    CHANNEL_LIST_ORDER = 'ChannelListOrder',
    CHANNEL_LIST_IMPORT = 'ChannelListImport',
    CHANNEL_DETAILS = 'ChannelDetails',
    CHANNEL_EDIT = 'ChannelEdit',
    CHANNEL_CREATE = 'ChannelCreate',
    XMLS_LIST = 'XmlsList',
}
