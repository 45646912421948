import axios from 'axios'
import { paramsSerializer } from './utils'
import {
    useRequestAuthInterceptor,
    useResponseErrorInterceptor,
    useRequestLanguageInterceptor,
} from './interceptors'

const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: import.meta.env.VITE_APP_CONFIGURATION_HUB_URL,
    paramsSerializer,
})

// response interceptors are called in order they are added
useRequestAuthInterceptor(axiosInstance)
useResponseErrorInterceptor(axiosInstance)
useRequestLanguageInterceptor(axiosInstance)

export abstract class BackendConfigurationHub {
    protected get = axiosInstance.get
    protected post = axiosInstance.post
    protected put = axiosInstance.put
    protected patch = axiosInstance.patch
    protected delete = axiosInstance.delete
}
