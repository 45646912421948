import { AbilityBuilder, Ability } from '@casl/ability'
import { ABILITY_ACTION, ABILITY_SUBJECT } from '@/constants'

type AppAbility = Ability<[ABILITY_ACTION, ABILITY_SUBJECT]>

export const ability: Ability = new Ability<[ABILITY_ACTION, ABILITY_SUBJECT]>(
    getUnauthenticatedRules()
)

export function defineAbilitiesForUnauthenticated(): void {
    ability.update(getUnauthenticatedRules())
}

export function defineAbilitiesForAuthenticated(): void {
    ability.update(getAuthenticatedRules())
}

function getUnauthenticatedRules() {
    const { rules, can } = new AbilityBuilder<AppAbility>(Ability)

    can(ABILITY_ACTION.READ, ABILITY_SUBJECT.UNAUTHENTICATED)

    return rules
}

function getAuthenticatedRules() {
    const { rules, can } = new AbilityBuilder<AppAbility>(Ability)

    can(ABILITY_ACTION.READ, ABILITY_SUBJECT.AUTHENTICATED)

    return rules
}
