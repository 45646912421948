import { pluralize } from '@/utils'
import { ILocales } from '../models'
import { LANGUAGE_FALLBACK } from '@/constants'

export const localesDe: ILocales = {
    common: {
        add: 'Hinzufügen',
        delete: 'Löschen',
        edit: 'Bearbeiten',
        save: 'Speichern',
        import: 'Importieren',
        statuses: {
            zero: 'Keine Statusmeldungen',
            one: 'Status',
            many: 'Statusmeldungen',
        },
        unknown: 'Unbekannt',
        none: 'Keine',
        close: 'Schließen',
        upload: 'Hochladen',
        accept: 'Akzeptieren',
        cancel: 'Abbrechen',
        areYouSure: 'Sind Sie sicher?',
        search: 'Suche',
        hotels: {
            zero: 'Keine Hotels',
            one: 'Hotel',
            many: 'Hotels',
        },
        rooms: {
            zero: 'Keine Zimmer',
            one: 'Zimmer',
            many: 'Zimmer',
        },
        results: {
            zero: 'Keine Ergebnisse',
            one: 'Ergebnis',
            many: 'Ergebnisse',
        },
        active: 'Aktiv',
        inactive: 'Inaktiv',
        data: {
            zero: 'Keine Daten',
            one: 'Datum',
            many: 'Daten',
        },
        activate: 'Aktivieren',
        deactivate: 'Deaktivieren',
        deactivatesIn: (time) => `Deaktiviert in ${time}`,
        default: 'Standard',
        versions: {
            zero: 'Keine Versionen',
            one: 'Version',
            many: 'Versionen',
        },
        makeDefault: 'Als Standard festlegen',
        deleteDescription:
            'Wenn ein Element gelöscht wird, ist es nicht mehr im System sichtbar und Verknüpfungen zu anderen Elementen existieren nicht mehr.',
        creationDate: 'Erstellungsdatum',
        deactivationDate: 'Deaktivierungsdatum',
        period: 'Periode',
        details: 'Einzelheiten',
    },
    validation: {
        required: 'Dieses Feld ist erforderlich',
        minLength: (length) =>
            pluralize(
                length,
                (length, variation) =>
                    `Das Feld muss mindestens ${length} ${variation} enthalten.`,
                {
                    one: 'Zeichen',
                    many: 'Zeichen',
                }
            ),
        maxLength: (length) =>
            pluralize(
                length,
                (length, variation) =>
                    `Das Feld darf nicht mehr als ${length} ${variation} enthalten.`,
                {
                    one: 'Zeichen',
                    many: 'Zeichen',
                }
            ),
        emailFormat: 'Die angegebene E-Mail-Adresse ist ungültig.',
        phoneFormat: 'Die angegebene Nummer ist falsch.',
        checkRequired: 'Das Aktivieren dieses Kontrollkästchens ist erforderlich.',
        numeric: 'Der Wert muss eine Zahl sein.',
        minValue: (value) => `Der Wert darf nicht kleiner als ${value} sein.`,
        maxValue: (value) => `Der Wert darf nicht größer als ${value} sein.`,
        minDate: (value) => `Das Datum darf nicht kleiner als ${value} sein.`,
        maxDate: (value) => `Das Datum darf nicht größer als ${value} sein.`,
        sameAs: (fieldName) => `Das Feld muss denselben Wert wie „${fieldName}“ haben.`,
        listNotEmpty: 'Sie müssen mindestens eine Option auswählen.',
        maxFileSize: (sizeInMB) => `Die maximal zulässige Dateigröße beträgt ${sizeInMB}MB`,
        fallbackTranslation: `Dieses Feld muss mindestens die Übersetzung „${LANGUAGE_FALLBACK}“ haben`,
    },
    auth: {
        hotelContextChanged: 'Das Hotel wurde umgestellt',
    },
    channels: {
        channels: {
            zero: 'Keine TV-Kanäle',
            one: 'TV-Kanal',
            many: 'TV-Kanäle',
        },
        order: 'Kanalnummern zuweisen',
        orderMode: {
            shift: 'Verschiebung',
            replace: 'Ersetzen',
        },
        saveOrder: 'Kanalnummerierung speichern',
        orderSaved: 'Die Kanalnummerierung wurde gespeichert',
        changeOrder: 'Reihenfolge ändern',
        name: 'Name',
        groups: {
            zero: 'Keine Gruppen von TV-Kanäle',
            one: 'Gruppe von TV-Kanäle',
            many: 'Gruppen von TV-Kanäle',
        },
        channelNumber: 'Kanal Nummer',
        autoSort: 'Auto Sortierung',
        frequency: 'Frequenz',
        broadcastType: {
            title: 'Broadcast-Typ',
            options: {
                unknown: 'Unbekannt',
                terrestrial: 'Terrestrial',
                terrestrial2: 'Terrestrial 2',
                satellite: 'satellite',
                satellite2: 'satellite 2',
                cable: 'Cable',
                cablestd: 'Cable std',
                cablehrc: 'Cable hrc',
                cableirc: 'Cable irc',
                analogpalbg: 'Analog pal bg',
                analogpaldk: 'Analog pal dk',
                analogpali: 'Analog pal i',
                analogpalm: 'Analog pal m',
                analogpaln: 'Analog pal n',
                analogsecambg: 'Analog secam bg',
                analogsecamdk: 'Analog secam dk',
                analogsecaml: 'Analog secam l',
                analogntsc: 'Analog ntsc',
                terrestrialatsc3: 'Terrestrial atsc3',
                cableatsc3: 'Cable atsc3',
            },
        },
        programNumber: 'SVCID',
        type: {
            title: 'Typ',
            options: {
                rf: 'RF',
                rf_data: 'RF data',
                ip: 'IP',
                ip_data: 'IP data',
                unknown: 'Unbekannt',
            },
        },
        kind: {
            title: 'Art',
            options: {
                radio: 'Radio',
                tv: 'TV',
                unknown: 'Unbekannt',
            },
        },
        response: {
            created: 'Der Kanal wurde erstellt',
            updated: 'Der Kanal wurde aktualisiert',
            deleted: 'Der Kanal wurde gelöscht',
        },
        channelsUploadDescription:
            'Laden Sie die neue Kanalliste, um ihre Eigenschaften zu verwalten',
        summary: 'Die neue Kanalliste enthält:',
        channelsInstallationStatus: 'Status der Kanalinstallation',
        epg: 'EPG',
    },
    hotelModes: {
        hotelModes: {
            zero: 'Kein Hotelmodus',
            one: 'Hotelmodus',
            many: 'Hotelmodi',
        },
        volume: {
            max: 'Maximale Lautstärke',
            min: 'Minimale Lautstärke',
            start: 'Startlautstärke',
        },
        response: {
            created: 'Der Hotelmodus wurde erstellt',
            updated: 'Der Hotelmodus wurde aktualisiert',
        },
    },
    devices: {
        clientVersion: 'Client-Version',
        updateTll: 'Aktualisieren Sie die TLL-Datei',
        notResponding: 'Das Gerät reagiert nicht',
        awaitingConnection: 'Warten auf Verbindung',
        offline: 'Offline',
        online: 'Online',
        registerDevice: 'Gerät registrieren',
        deviceModel: 'Gerätemodell',
        manufacturer: 'Hersteller',
        editDevice: 'Gerät bearbeiten',
        deviceId: 'Geräte-ID',
        serialNumber: 'Seriennummer',
        deviceHasBeenRegistered: 'Gerät wurde registriert',
        deviceHasBeenDeleted: 'Gerät wurde gelöscht',
        deviceHasBeenUpdated: 'Gerät wurde aktualisiert',
        devices: {
            zero: 'Keine Geräte',
            one: 'Gerät',
            many: 'Geräte',
        },
        deviceServiceModes: {
            zero: 'Kein Service-Modus',
            one: 'Service-Modus',
            many: 'Service-Modi',
        },
        deviceServiceModeOn: 'Aktiviere Service-Modus',
        deviceServiceModeOff: 'Deaktiviere Service-Modus',
        deviceHasBeenActivated: 'Service-Modus wurde aktiviert',
        deviceHasBeenDeactivated: 'Service-Modus wurde deaktiviert',
        deviceServiceModeIsActive: 'Service-Modus ist aktiv',
        timestampLastToken: 'Zeitstempel des letzten aktiven Geräts',
        serviceModes: {
            zero: 'Kein Service-Modus',
            one: 'Service-Modus',
            many: 'Service-Modi',
        },
        serviceModeActivated: 'Service-Modus aktiviert',
        serviceModeDeactivated: 'Service-Modus deaktiviert',
        modelDoesNotExistInSeries: (model) =>
            `Das ${model} Gerät aus der .tll Datei ist keiner Serie zugeordnet. Bitte verwenden Sie eine andere Datei oder wenden Sie sich an den technischen Support.`,
        modelDoesNotExistInHotel: (model) =>
            `Das ${model} Gerät aus der .tll Datei wurde nicht im Hotel registriert. Bitte verwenden Sie eine andere Datei.`,
        tllDeviceSeries: 'Die .tll-Datei wird an die Serien verteilt',
        applyToAllDevices:
            'Übertrage die .tll-Datei an alle kompatiblen Modelle in diesem Hotel',
        incompatibleSeries: 'Die .tll Datei ist nicht kompatibel mit den Serien',
        numberIncompatibleDevices: 'Anzahl der inkompatiblen Geräte',
        downloadDevices: 'Geräteliste herunterladen',
        channelListHistory: 'Kanallistenverlauf',
    },
    xmls: {
        tllFiles: {
            zero: 'Keine .tll-Dateien',
            one: '.tll-Datei',
            many: '.tll-Dateien',
        },
        updateStatus: {
            start: 'Das Gerät hat die Bestellung angenommen',
            fetching: 'Herunterladen der TLL-Datei',
            fetchError: 'Die TLL-Datei konnte nicht heruntergeladen werden',
            updating: 'Aktualisierung der .tll-Datei',
            updateError: 'Die Aktualisierung der TLL-Datei ist fehlgeschlagen',
            updated: 'Die .tll-Datei wurde aktualisiert',
        },
        distributeTllFile: 'Datei .tll verteilen',
        deleteTllFile: 'Datei .tll löschen',
        response: {
            uploaded: 'Datei .tll wurde hochgeladen',
            deleted: 'Datei .tll wurde gelöscht',
        },
        validation: {
            firstPart: 'Zur Bestätigung geben Sie',
            secondPart: 'im Feld unten ein',
            description:
                'Der Bestätigungscode ist auch der Name des Hotels, in dem die .tll-Datei verteilt wird',
        },
        distributeTllFileDescription:
            'Laden Sie die neue tll-Datei hoch, um die Kanäle auf allen kompatiblen Geräten im Hotel zu aktualisieren',
        summary: 'Die .tll-Datei wird an alle kompatiblen Modelle neu verteilt',
    },
    users: {
        users: {
            zero: 'Keine Benutzer',
            one: 'Benutzer',
            many: 'Benutzer',
        },
        logins: {
            zero: 'Keine Anmeldungen',
            one: 'Anmeldung',
            many: 'Anmeldungen',
        },
        signIn: 'Anmelden',
        signOut: 'Abmelden',
        passwordChange: 'Passwortänderung',
        myAccount: 'Mein Konto',
        usernames: {
            zero: 'Keine Benutzernamen',
            one: 'Benutzername',
            many: 'Benutzernamen',
        },
        password: {
            zero: 'Kein Passwort',
            one: 'Passwort',
            many: 'Passwörter',
        },
        repeatPassword: 'Passwort wiederholen',
        changePassword: 'Passwort ändern',
    },
    technician: {
        technician: 'Techniker',
    },
}
