import { pluralize } from '@/utils'
import { ILocales } from '../models'
import { LANGUAGE_FALLBACK } from '@/constants'

export const localesPl: ILocales = {
    common: {
        add: 'Dodaj',
        delete: 'Usuń',
        edit: 'Edytuj',
        save: 'Zapisz',
        import: 'Importuj',
        statuses: {
            zero: 'Brak statusów',
            one: 'Status',
            many: 'Statusy',
        },
        unknown: 'Nieznany',
        none: 'Brak',
        close: 'Zamknij',
        upload: 'Prześlij',
        accept: 'Akceptuj',
        cancel: 'Anuluj',
        areYouSure: 'Na pewno?',
        search: 'Szukaj',
        hotels: {
            zero: 'Brak hoteli',
            one: 'Hotel',
            many: 'Hotele',
        },
        rooms: {
            zero: 'Brak pokoi',
            one: 'Pokój',
            many: 'Pokoje',
        },
        results: {
            zero: 'Brak wyników',
            one: 'Wynik',
            many: 'Wyniki',
        },
        active: 'Aktywny',
        data: {
            zero: 'Brak danych',
            one: 'Dana',
            many: 'Dane',
        },
        inactive: 'Nieaktywny',
        activate: 'Aktywuj',
        deactivate: 'Dezaktywuj',
        deactivatesIn: (time) => `Wyłączy się za ${time}`,
        default: 'Domyślny',
        versions: {
            zero: 'Brak wersji',
            one: 'Wersja',
            many: 'Wersje',
        },
        makeDefault: 'Ustaw jako domyślny',
        deleteDescription:
            'Jeśli element zostanie usunięty, przestanie być widoczny systemie, a jego powiązania do innych elementów przestaną istnieć.',
        creationDate: 'Data utworzenia',
        deactivationDate: 'Data deazaktywacji',
        period: 'Okres',
        details: 'Szczegóły',
    },
    validation: {
        required: 'Pole jest wymagane',
        minLength: (length) =>
            pluralize(
                length,
                (length, variation) => `Pole musi zawierać co najmniej ${length} ${variation}.`,
                {
                    one: 'znak',
                    few: 'znaki',
                    many: 'znaków',
                }
            ),
        maxLength: (length) =>
            pluralize(
                length,
                (length, variation) =>
                    `Pole nie może zawierać więcej niż ${length} ${variation}.`,
                {
                    one: 'znak',
                    few: 'znaki',
                    many: 'znaków',
                }
            ),
        emailFormat: 'Podany adres e-mail jest nieprawidłowy.',
        phoneFormat: 'Podany numer jest niepoprawny.',
        checkRequired: 'Zaznaczenie tego pola jest wymagane.',
        numeric: 'Wartość musi być liczbą.',
        minValue: (value) => `Wartość nie może być mniejsza niż ${value}.`,
        maxValue: (value) => `Wartość nie może być większa niż ${value}.`,
        minDate: (value) => `Data nie może być mniejsza niż ${value}.`,
        maxDate: (value) => `Data nie może być większa niż ${value}.`,
        sameAs: (fieldName) => `Pole musi mieć tę samą wartość co pole '${fieldName}'.`,
        listNotEmpty: 'Należy wybrać przynajmniej jedną opcję.',
        maxFileSize: (sizeInMB) => `Maksymalny dobuszczalny rozmiar pliku to ${sizeInMB}MB`,
        fallbackTranslation: `To pole musi mieć przynajmniej tłumaczenie na język "${LANGUAGE_FALLBACK}"`,
    },
    auth: {
        hotelContextChanged: 'Przełączono hotel',
    },
    channels: {
        channels: {
            zero: 'Brak kanałów',
            one: 'Kanał',
            many: 'Kanały',
        },
        order: 'Przypisz numery kanałów',
        orderMode: {
            shift: 'Przesuwanie',
            replace: 'Zamiana miejsc',
        },
        saveOrder: 'Zapisz numerację kanałów',
        orderSaved: 'Numeracja kanałów została zapisana',
        changeOrder: 'Zmień numerację',
        name: 'Nazwa',
        groups: {
            zero: 'Brak grup kanałów',
            one: 'Grupa kanałów',
            many: 'Grupy kanałów',
        },
        channelNumber: 'Numer kanału',
        autoSort: 'Auto sortowanie',
        frequency: 'Częstotliwość',
        broadcastType: {
            title: 'Typ transmisji',
            options: {
                unknown: 'Nieznany',
                terrestrial: 'Naziemna',
                terrestrial2: 'Naziemna 2',
                satellite: 'Satelita',
                satellite2: 'Satelita 2',
                cable: 'Kablowa',
                cablestd: 'Kablowa std',
                cablehrc: 'Kablowa hrc',
                cableirc: 'Kablowa irc',
                analogpalbg: 'Analogowa pal bg',
                analogpaldk: 'Analogowa pal dk',
                analogpali: 'Analogowa pal i',
                analogpalm: 'Analogowa pal m',
                analogpaln: 'Analogowa pal n',
                analogsecambg: 'Analogowa secam bg',
                analogsecamdk: 'anaanalogowalog secam dk',
                analogsecaml: 'Analogowa secam l',
                analogntsc: 'Analogowa ntsc',
                terrestrialatsc3: 'Naziemna atsc3',
                cableatsc3: 'Kablowa atsc3',
            },
        },
        programNumber: 'SVCID',
        type: {
            title: 'Typ',
            options: {
                rf: 'RF',
                rf_data: 'RF data',
                ip: 'IP',
                ip_data: 'IP data',
                unknown: 'Nieznany',
            },
        },
        kind: {
            title: 'Rodzaj',
            options: {
                radio: 'Radio',
                tv: 'TV',
                unknown: 'Nieznany',
            },
        },
        response: {
            created: 'Kanał został utworzony',
            updated: 'Kanał został zaktualizowany',
            deleted: 'Kanał został usunięty',
        },
        channelsUploadDescription:
            'Prześlij nową listę kanałów do zarządzania ich właściwościami',
        summary: 'Nowa lista kanałów zawiera:',
        channelsInstallationStatus: 'Status instalacji kanałów',
        epg: 'EPG',
    },
    hotelModes: {
        hotelModes: {
            zero: 'Brak trybu hotelowego',
            one: 'Tryb hotelowy',
            many: 'Tryby hotelowe',
        },
        volume: {
            max: 'Głośność maksymalna',
            min: 'Głośność minimalna',
            start: 'Głośność inicjacyjna',
        },
        response: {
            created: 'Tryb hotelowy został utworzony',
            updated: 'Tryb hotelowy został zaktualizowany',
        },
    },
    devices: {
        clientVersion: 'Wersja klienta',
        updateTll: 'Aktualizuj plik .tll',
        notResponding: 'Urządzenie nie odpowiada',
        awaitingConnection: 'Oczekiwanie na połączenie',
        offline: 'Offline',
        online: 'Online',
        registerDevice: 'Zarejestruj urządzenie',
        deviceModel: 'Model urządzenia',
        manufacturer: 'Producent',
        editDevice: 'Edytuj urządzenie',
        deviceId: 'ID urządzenia',
        serialNumber: 'Numer seryjny',
        deviceHasBeenRegistered: 'Urządzenie zostało zarejestrowane',
        deviceHasBeenDeleted: 'Urządzenie zostało usunięte',
        deviceHasBeenUpdated: 'Urządzenie zostało zaktualizowane',
        devices: {
            zero: 'Brak urządzeń',
            one: 'Urządzenie',
            many: 'Urządzenia',
        },
        deviceServiceModes: {
            zero: 'Brak trybu serwisowego',
            one: 'Tryb serwisowy',
            many: 'Tryby serwisowe',
        },
        deviceServiceModeOn: 'Aktywuj tryb serwisowy',
        deviceServiceModeOff: 'Dezaktywuj tryb serwisowy',
        deviceHasBeenActivated: 'Tryb serwisiwy został aktywowany',
        deviceHasBeenDeactivated: 'Tryb serwisiwy został dezaktywowany',
        deviceServiceModeIsActive: 'Wyłączy się za',
        timestampLastToken: 'Ostatni odczyt aktywnego urządzenia',
        serviceModes: {
            zero: 'Brak trybu serwisowego',
            one: 'Tryb serwisowy',
            many: 'Tryby serwisowe',
        },
        serviceModeActivated: 'Tryb serwisowy aktywowany',
        serviceModeDeactivated: 'Tryb serwisowy dezaktywowany',
        modelDoesNotExistInSeries: (model) =>
            `Urządzenie ${model} z pliku .tll nie jest przypisane do żadnej serii. Użyj innego pliku bądź skontaktuj się ze wsparciem technicznym.`,
        modelDoesNotExistInHotel: (model) =>
            `Urządzenie ${model} z pliku .tll nie zostało zarejestrowane w hotelu. Użyj innego pliku.`,
        tllDeviceSeries: 'Plik .tll zostanie rozdystrybuowany do serii',
        applyToAllDevices:
            'Prześlij plik .tll do wszystkich kompatybilnych modeli w tym hotelu',
        incompatibleSeries: 'Plik .tll jest niekompatybilny z seriami',
        numberIncompatibleDevices: 'Ilość niekompatybilnych urządzeń',
        downloadDevices: 'Pobierz listę urządzeń',
        channelListHistory: 'Historia listy kanałów',
    },
    xmls: {
        tllFiles: {
            zero: 'Brak plików .tll',
            one: 'Plik .tll',
            many: 'Pliki .tll',
        },
        updateStatus: {
            start: 'Urządzenie przyjęło zlecenie',
            fetching: 'Pobieranie pliku .tll',
            fetchError: 'Pobranie pliku .tll nie powiodło się',
            updating: 'Aktualizowanie pliku .tll',
            updateError: 'Aktualizacja pliku .tll nie powiodła się',
            updated: 'Plik .tll został zaktualizowany',
        },
        distributeTllFile: 'Rozdystrybuuj plik .tll',
        deleteTllFile: 'Usuń plik .tll',
        response: {
            uploaded: 'Plik .tll został przesłany',
            deleted: 'Plik .tll został usuniedy',
        },
        validation: {
            firstPart: 'Aby potwierdzić, wpisz',
            secondPart: 'w polu poniżej',
            description:
                'Hasło jest jednocześnie nazwą hotelu w którym zostanie rozdystrybuowany plik .tll',
        },
        distributeTllFileDescription:
            'Prześlij nowy plik tll, aby zaktualizować kanały na wszystkich kompatybilnych urządzeniach w hotelu',
        summary: 'Plik .tll zostanie rozdystrybuowany do wszystkich kompatybilnych modeli',
    },
    users: {
        users: {
            zero: 'Brak użytkowników',
            one: 'Użytkownik',
            many: 'Użytkownicy',
        },
        logins: {
            zero: 'Brak loginów',
            one: 'Login',
            many: 'Loginy',
        },
        signIn: 'Zaloguj się',
        signOut: 'Wyloguj się',
        passwordChange: 'Zmiana hasła',
        myAccount: 'Moje konto',
        usernames: {
            zero: 'Brak nazwy użytkownika',
            one: 'Nazwa użytkownika',
            many: 'Nazwy użytkowników',
        },
        password: {
            zero: 'Brak hasła',
            one: 'Hasło',
            many: 'Hasła',
        },
        repeatPassword: 'Powtórz hasło',
        changePassword: 'Zmień hasło',
    },
    technician: {
        technician: 'Technik',
    },
}
