import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'

export interface IServiceErrorResponseData<T = unknown> {
    error: string
    message: string
    details?: T
}

export interface IServiceErrorResponse extends AxiosResponse {
    status: number
    data: IServiceErrorResponseData
}

export class ServiceError {
    public name: string
    public message: string
    public config: AxiosRequestConfig
    public code?: string
    public request?: unknown
    public response: IServiceErrorResponse
    public isAxiosError: boolean

    constructor(error: AxiosError) {
        this.name = error.name || 'ServiceError'
        this.message = error.message || 'API error'
        this.config = error.config || {}
        this.code = error.code
        this.request = error.request
        this.response = this.getDefaultResponse(error.response)
        this.isAxiosError = error.isAxiosError || false
    }

    private getDefaultResponse(
        response?: AxiosResponse<IServiceErrorResponseData>
    ): IServiceErrorResponse {
        const { error, message, ...details } = response?.data || {}

        return {
            status: 0,
            statusText: '',
            headers: {},
            config: {},
            ...response,
            data: {
                error: error || this.name,
                message: message || this.message,
                ...(details && { details }),
            },
        }
    }
}
