/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** CreatedPushTokenResponse */
export interface CreatedPushTokenResponse {
    /** Token */
    token: string
}

/** HTTPValidationError */
export interface HTTPValidationError {
    /** Detail */
    detail?: ValidationError[]
}

/** PushTopicSubscriptionChange */
export interface PushTopicSubscriptionChange {
    /**
     * Push Tokens
     * @minItems 1
     */
    push_tokens: string[]
    /**
     * Topic
     * @minLength 1
     * @maxLength 128
     */
    topic: string
}

/** PushTopicSubscriptionResponse */
export interface PushTopicSubscriptionResponse {
    /** Success Count */
    success_count: number
}

/** SendPushMessageResponse */
export interface SendPushMessageResponse {
    /**
     * Failed Count
     * @min 0
     */
    failed_count: number
    /**
     * Sent Count
     * @min 0
     */
    sent_count: number
}

/** SendPushMessageToTokensRequest */
export interface SendPushMessageToTokensRequest {
    /** Payload */
    payload: object
    /**
     * Push Tokens
     * @minItems 1
     */
    push_tokens: string[]
}

/** SendPushMessageToTopicsRequest */
export interface SendPushMessageToTopicsRequest {
    /** Payload */
    payload: object
    /**
     * Topics
     * @minItems 1
     */
    topics: string[]
}

/** ValidationError */
export interface ValidationError {
    /** Location */
    loc: (string | number)[]
    /** Message */
    msg: string
    /** Error Type */
    type: string
}

import type {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    HeadersDefaults,
    ResponseType,
} from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
    extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean
    /** request path */
    path: string
    /** content type of request body */
    type?: ContentType
    /** query params */
    query?: QueryParamsType
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType
    /** request body */
    body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown>
    extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
    secure?: boolean
    format?: ResponseType
}

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
    public instance: AxiosInstance
    private securityData: SecurityDataType | null = null
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
    private secure?: boolean
    private format?: ResponseType

    constructor({
        securityWorker,
        secure,
        format,
        ...axiosConfig
    }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' })
        this.secure = secure
        this.format = format
        this.securityWorker = securityWorker
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data
    }

    protected mergeRequestParams(
        params1: AxiosRequestConfig,
        params2?: AxiosRequestConfig
    ): AxiosRequestConfig {
        const method = params1.method || (params2 && params2.method)

        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method &&
                    this.instance.defaults.headers[
                        method.toLowerCase() as keyof HeadersDefaults
                    ]) ||
                    {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        }
    }

    protected stringifyFormItem(formItem: unknown) {
        if (typeof formItem === 'object' && formItem !== null) {
            return JSON.stringify(formItem)
        } else {
            return `${formItem}`
        }
    }

    protected createFormData(input: Record<string, unknown>): FormData {
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key]
            const propertyContent: any[] = property instanceof Array ? property : [property]

            for (const formItem of propertyContent) {
                const isFileType = formItem instanceof Blob || formItem instanceof File
                formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
            }

            return formData
        }, new FormData())
    }

    public request = async <T = any, _E = any>({
        secure,
        path,
        type,
        query,
        format,
        body,
        ...params
    }: FullRequestParams): Promise<AxiosResponse<T>> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {}
        const requestParams = this.mergeRequestParams(params, secureParams)
        const responseFormat = format || this.format || undefined

        if (
            type === ContentType.FormData &&
            body &&
            body !== null &&
            typeof body === 'object'
        ) {
            body = this.createFormData(body as Record<string, unknown>)
        }

        if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
            body = JSON.stringify(body)
        }

        return this.instance.request({
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
            },
            params: query,
            responseType: responseFormat,
            data: body,
            url: path,
        })
    }
}

/**
 * @title HTV Push service
 * @version 0.1.0
 *
 * A push service backend. Manages push connections
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    pushService = {
        /**
         * No description
         *
         * @tags Push Service
         * @name CreatePushTokenApiV1PushTokensPost
         * @summary Creates a new push token
         * @request POST:/api/v1/push-tokens
         * @secure
         * @response `201` `CreatedPushTokenResponse` Successful Response
         */
        createPushTokenApiV1PushTokensPost: (params: RequestParams = {}) =>
            this.request<CreatedPushTokenResponse, any>({
                path: `/api/v1/push-tokens`,
                method: 'POST',
                secure: true,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Push Service
         * @name SendPushMessageToTokensApiV1PushMessagesSendToTokensRequestsPost
         * @summary Sends a push message for specific push tokens
         * @request POST:/api/v1/push-messages/send-to-tokens-requests
         * @secure
         * @response `201` `SendPushMessageResponse` Successful Response
         * @response `422` `HTTPValidationError` Validation Error
         */
        sendPushMessageToTokensApiV1PushMessagesSendToTokensRequestsPost: (
            data: SendPushMessageToTokensRequest,
            params: RequestParams = {}
        ) =>
            this.request<SendPushMessageResponse, HTTPValidationError>({
                path: `/api/v1/push-messages/send-to-tokens-requests`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Push Service
         * @name SendPushMessageToTopicsApiV1PushMessagesSendToTopicsRequestsPost
         * @summary Sends a push message for specific topics
         * @request POST:/api/v1/push-messages/send-to-topics-requests
         * @secure
         * @response `201` `SendPushMessageResponse` Successful Response
         * @response `422` `HTTPValidationError` Validation Error
         */
        sendPushMessageToTopicsApiV1PushMessagesSendToTopicsRequestsPost: (
            data: SendPushMessageToTopicsRequest,
            params: RequestParams = {}
        ) =>
            this.request<SendPushMessageResponse, HTTPValidationError>({
                path: `/api/v1/push-messages/send-to-topics-requests`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Push Service
         * @name SubscribeToTopicsApiV1PushTopicsSubscribedPost
         * @summary Subscribes specific push tokens to a selected topic
         * @request POST:/api/v1/push-topics/subscribed
         * @secure
         * @response `201` `PushTopicSubscriptionResponse` Successful Response
         * @response `422` `HTTPValidationError` Validation Error
         */
        subscribeToTopicsApiV1PushTopicsSubscribedPost: (
            data: PushTopicSubscriptionChange,
            params: RequestParams = {}
        ) =>
            this.request<PushTopicSubscriptionResponse, HTTPValidationError>({
                path: `/api/v1/push-topics/subscribed`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Push Service
         * @name UnsubscribeFromTopicsApiV1PushTopicsUnsubscribedPost
         * @summary Subscribes specific push tokens to a selected topic
         * @request POST:/api/v1/push-topics/unsubscribed
         * @secure
         * @response `201` `PushTopicSubscriptionResponse` Successful Response
         * @response `422` `HTTPValidationError` Validation Error
         */
        unsubscribeFromTopicsApiV1PushTopicsUnsubscribedPost: (
            data: PushTopicSubscriptionChange,
            params: RequestParams = {}
        ) =>
            this.request<PushTopicSubscriptionResponse, HTTPValidationError>({
                path: `/api/v1/push-topics/unsubscribed`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    }
    webSocketHub = {
        /**
         * No description
         *
         * @tags Web Socket Hub
         * @name ProcessWebsocketEventsPushStreamsTokenPost
         * @summary Creates and opens a new push connection
         * @request POST:/push-streams/{token}
         * @response `200` `any` Successful Response
         * @response `422` `HTTPValidationError` Validation Error
         */
        processWebsocketEventsPushStreamsTokenPost: (
            token: string,
            params: RequestParams = {}
        ) =>
            this.request<any, HTTPValidationError>({
                path: `/push-streams/${token}`,
                method: 'POST',
                format: 'json',
                ...params,
            }),
    }
}
