import {
    Backend,
    IChannelGroup,
    IRawRequestListResponse,
    IRequestListParams,
    IRequestListResponse,
} from '@/models'
import { ConverterChannelGroup } from './converters'
import { IRawChannelGroup, IRawChannelOrderItem } from './models'
import { REQUEST_LIST_LIMIT } from '@/constants'

export const ServiceChannelGroups = new (class ServiceChannelGroups extends Backend {
    async fetchChannelGroups({
        offset,
        limit = REQUEST_LIST_LIMIT.DEFAULT,
    }: IRequestListParams): Promise<IRequestListResponse<IChannelGroup>> {
        const {
            data: { results, count },
        } = await this.get<IRawRequestListResponse<IRawChannelGroup>>(
            `/technician/television/channels-groups/`,
            {
                params: { offset, limit },
            }
        )

        return {
            items: await Promise.all(results.map(ConverterChannelGroup.fromRaw)),
            hasMoreItems: results.length === limit,
            total: count,
        }
    }

    async fetchChannelGroup(id: string): Promise<IChannelGroup> {
        const { data } = await this.get<IRawChannelGroup>(
            `/technician/television/channels-groups/${id}`
        )

        return await ConverterChannelGroup.fromRaw(data)
    }

    async setChannelGroupChannelsOrder(
        id: string,
        orderedItems: IRawChannelOrderItem[]
    ): Promise<void> {
        await this.put(`technician/television/channels-groups/${id}/channels-order/`, {
            channels_order: orderedItems,
        })
    }
})()
