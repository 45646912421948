import { ABILITY_ACTION, ABILITY_SUBJECT, ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'
import { router } from './instance'
import { navigationGuard } from './utils'
import { RouteLocation } from 'vue-router'
import { routesAuthenticated, routesUrlResolver } from './routes'

const routes: IRouteConfig[] = [
    ...routesUrlResolver,
    {
        name: ROUTE.START,
        path: '/',
        redirect: (to: RouteLocation) => {
            return { name: ROUTE.DEVICE_REGISTER, query: to.query }
        },
    },
    {
        name: ROUTE.SIGN_IN,
        path: '/sign-in',
        component: () => import('@/views/SignIn/SignIn.vue'),
        props: true,
        meta: {
            title: (intl) => intl.t().users.signIn,
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.UNAUTHENTICATED,
        },
    },
    {
        name: ROUTE.PASSWORD_CHANGE,
        path: '/password-change/:ticket',
        component: () => import('@/views/PasswordChange/PasswordChange.vue'),
        props: true,
        meta: {
            title: (intl) => intl.t().users.passwordChange,
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.UNAUTHENTICATED,
        },
    },
    {
        path: '/app',
        component: () => import('@/views/Authenticated/Authenticated.vue'),
        children: [...routesAuthenticated],
    },
    {
        path: '/:pathMatch(.*)*', // 404
        redirect: { name: ROUTE.START },
    },
]

routes.forEach((route) => router.addRoute(route))
router.beforeEach(navigationGuard)

export { router }
