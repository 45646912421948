import { createApp, h } from 'vue'
import App from './App.vue'
import { router } from './router'
import { store } from './store'
import { toasts, vuetify, intl, pinia, sentry, pushApi } from './plugins'
import { abilitiesPlugin } from '@casl/vue'
import { ability } from './ability'

const app = createApp({ render: () => h(App) })

app.use(store)
app.use(vuetify)
app.use(pinia)
app.use(abilitiesPlugin, ability, { useGlobalProperties: true })
app.use(toasts)
app.use(intl)
app.use(pushApi)
app.use(router)
app.use(sentry, router)

app.mount('#app')
