import { ShallowReactive, reactive } from 'vue'
import {
    IRequestListParams,
    IRequestListResponse,
    IServiceErrorResponse,
    IStateList,
    IStateListItem,
} from '@/models'
import { ServiceToasts } from '@/services/ServiceToasts'

type FetchHandler<R, F, O extends unknown[]> = (
    options: IRequestListParams<F>,
    ...optionalArgs: O
) => Promise<IRequestListResponse<R>>

export const useResourceStore = <T extends IStateListItem, F, O extends unknown[]>(
    fetchHandler: FetchHandler<T, F, O>,
    filters: F
) => {
    const getDefaultFilters = () => filters
    const state = reactive<IStateList<T, F>>({
        filters: getDefaultFilters(),
        items: undefined,
        isFetchingItems: false,
        hasFetchedAllItems: false,
    }) as ShallowReactive<IStateList<T, F>>

    const setItems = (items?: T[]) => {
        state.items = items
    }

    const addItems = (items: T[]) => {
        if (!state.items) state.items = []

        state.items.push(...items)
    }

    const updateItem = (updatedItem: T) => {
        if (!state.items) return

        const index = state.items.findIndex(({ id }) => id === updatedItem.id)

        if (index !== -1) state.items[index] = updatedItem
    }

    const removeItem = (id: Uuid) => {
        if (!state.items) return

        state.items = state.items.filter((item) => item.id !== id)
    }

    const setIsFetchingItems = (isFetchingItems: boolean) => {
        state.isFetchingItems = isFetchingItems
    }

    const setHasFetchedAllItems = (hasFetchedAllItems: boolean) => {
        state.hasFetchedAllItems = hasFetchedAllItems
    }

    const setFilters = (filters: F) => {
        state.filters = filters
    }

    const resetFilters = () => {
        state.filters = getDefaultFilters()
    }

    const clearItems = (): void => {
        setItems(undefined)
        setHasFetchedAllItems(false)
    }

    const fetchAndSetItems = async (...optionalArgs: O): Promise<void> => {
        setIsFetchingItems(true)

        try {
            const { items, hasMoreItems } = await fetchHandler(
                { filters: state.filters, offset: 0 },
                ...optionalArgs
            )

            setHasFetchedAllItems(!hasMoreItems)
            setItems(items)
        } catch (error) {
            ServiceToasts.createServiceErrorToast(error as IServiceErrorResponse)
        }

        setIsFetchingItems(false)
    }

    const fetchAndAddMoreItems = async (...optionalArgs: O): Promise<void> => {
        setIsFetchingItems(true)

        try {
            const { items, hasMoreItems } = await fetchHandler(
                { filters: state.filters, offset: state.items?.length || 0 },
                ...optionalArgs
            )

            setHasFetchedAllItems(!hasMoreItems)
            addItems(items)
        } catch (error) {
            ServiceToasts.createServiceErrorToast(error as IServiceErrorResponse)
        }

        setIsFetchingItems(false)
    }

    return {
        state,
        setItems,
        addItems,
        updateItem,
        removeItem,
        setIsFetchingItems,
        setFilters,
        resetFilters,
        fetchAndSetItems,
        fetchAndAddMoreItems,
        clearItems,
    }
}
