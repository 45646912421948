import { ABILITY_ACTION, ABILITY_SUBJECT, ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'

export const routesAuthenticated: IRouteConfig[] = [
    {
        name: ROUTE.DEVICE_REGISTER,
        path: '/devices/register',
        component: () =>
            import('@/views/Authenticated/views/Devices/RegisterDevice/RegisterDevice.vue'),
        meta: {
            title: (intl) => intl.t().devices.registerDevice,
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
        },
    },
    {
        name: ROUTE.DEVICE_EDIT,
        path: 'devices/edit/:id',
        component: () =>
            import('@/views/Authenticated/views/Devices/DeviceEdit/DeviceEdit.vue'),
        props: true,
        meta: {
            title: (intl) => intl.t().devices.editDevice,
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
        },
    },
    {
        name: ROUTE.DEVICE_DETAILS,
        path: 'devices/:id',
        component: () =>
            import('@/views/Authenticated/views/Devices/DeviceDetails/DeviceDetails.vue'),
        props: true,
        redirect: { name: ROUTE.DEVICE_DETAILS_DETAILS },
        children: [
            {
                name: ROUTE.DEVICE_DETAILS_DETAILS,
                path: 'details',
                component: () =>
                    import(
                        '@/views/Authenticated/views/Devices/DeviceDetails/views/Details/Details.vue'
                    ),
                meta: {
                    title: (intl) => intl.t().devices.devices.one,
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DEVICE_DETAILS_CHANNELS_HISTORY,
                path: 'channels-history',
                component: () =>
                    import(
                        '@/views/Authenticated/views/Devices/DeviceDetails/views/ChannelsHistory/ChannelsHistory.vue'
                    ),
                meta: {
                    title: (intl) => intl.t().devices.devices.one,
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
        ],
    },
    {
        name: ROUTE.DEVICE_LIST,
        path: 'devices',
        component: () => import('@/views/Authenticated/views/Devices/Devices/Devices.vue'),
        meta: {
            title: (intl) => intl.t().common.hotels.one,
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
        },
    },
    {
        name: ROUTE.HOTEL_MODE,
        path: 'hotel-mode',
        component: () =>
            import('@/views/Authenticated/views/HotelModes/HotelMode/HotelMode.vue'),
        meta: {
            title: (intl) => intl.t().common.hotels.one,
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
        },
    },
    {
        name: ROUTE.HOTEL_MODE_EDIT,
        path: 'hotel-mode/:hotelModeId/edit',
        component: () =>
            import('@/views/Authenticated/views/HotelModes/HotelModeEdit/HotelModeEdit.vue'),
        props: true,
        meta: {
            title: (intl) => intl.t().common.hotels.one,
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
        },
    },
    {
        name: ROUTE.HOTEL_MODE_CREATE,
        path: 'hotel-mode/:hotelId/create',
        component: () =>
            import(
                '@/views/Authenticated/views/HotelModes/HotelModeCreate/HotelModeCreate.vue'
            ),
        props: true,
        meta: {
            title: (intl) => intl.t().common.hotels.one,
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
        },
    },
    {
        name: ROUTE.CHANNELS,
        path: 'channels',
        component: () => import('@/views/Authenticated/views/Channels/Channels/Channels.vue'),
        props: true,
        redirect: { name: ROUTE.CHANNEL_LIST },
        children: [
            {
                name: ROUTE.CHANNEL_LIST,
                path: 'channels',
                component: () =>
                    import(
                        '@/views/Authenticated/views/Channels/Channels/views/Channels/Channels.vue'
                    ),
                meta: {
                    title: (intl) => intl.t().common.hotels.one,
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.XMLS_LIST,
                path: 'xmls',
                component: () =>
                    import('@/views/Authenticated/views/Channels/Channels/views/Xmls/Xmls.vue'),
                meta: {
                    title: (intl) => intl.t().common.hotels.one,
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
        ],
    },
    {
        name: ROUTE.CHANNEL_LIST_ORDER,
        path: 'channels/order',
        component: () =>
            import('@/views/Authenticated/views/Channels/ChannelsOrder/ChannelsOrder.vue'),
        meta: {
            title: (intl) => intl.t().channels.order,
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
        },
    },
    {
        name: ROUTE.CHANNEL_LIST_IMPORT,
        path: 'channels/import',
        component: () =>
            import('@/views/Authenticated/views/Channels/ChannelsImport/ChannelsImport.vue'),
        meta: {
            title: (intl) => intl.t().common.import,
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
        },
    },
    {
        name: ROUTE.CHANNEL_DETAILS,
        path: 'channels/:channelId/details',
        component: () =>
            import('@/views/Authenticated/views/Channels/ChannelDetails/ChannelDetails.vue'),
        props: true,
        meta: {
            title: (intl) => intl.t().common.hotels.one,
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
        },
    },
    {
        name: ROUTE.CHANNEL_EDIT,
        path: 'channels/:channelId/edit',
        component: () =>
            import('@/views/Authenticated/views/Channels/ChannelEdit/ChannelEdit.vue'),
        props: true,
        meta: {
            title: (intl) => intl.t().common.hotels.one,
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
        },
    },
    {
        name: ROUTE.CHANNEL_CREATE,
        path: 'channels/create',
        component: () =>
            import('@/views/Authenticated/views/Channels/ChannelCreate/ChannelCreate.vue'),
        props: true,
        meta: {
            title: (intl) => intl.t().common.hotels.one,
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
        },
    },
    {
        name: ROUTE.MY_ACCOUNT,
        path: '/my-account',
        component: () => import('@/views/Authenticated/views/User/MyAccount/MyAccount.vue'),
        meta: {
            title: (intl) => intl.t().users.myAccount,
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
        },
    },
]
