import { getStorageOrBrowserOrFallbackLocale } from '@/utils'
import { createVuetify } from 'vuetify'
import { pl, de, en } from 'vuetify/locale'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import colors from 'vuetify/util/colors'

const colorsPalette = {
    primary: colors.teal.base,
    success: colors.green.base,
    warning: colors.orange.base,
    error: colors.red.base,
    info: colors.blue.base,
}

export const vuetify = createVuetify({
    theme: {
        themes: {
            light: {
                dark: false,
                colors: colorsPalette,
            },
            dark: {
                dark: true,
                colors: colorsPalette,
            },
        },
    },
    icons: {
        defaultSet: 'mdi',
        aliases: {
            ...aliases,
        },
        sets: {
            mdi,
        },
    },
    locale: {
        messages: { pl, de, en },
        locale: getStorageOrBrowserOrFallbackLocale().substr(0, 2),
    },
})
