import { IPluralRule } from '@/models'

export const pluralize = (
    value: number,
    textPattern: (value: number, variation: string) => string,
    variations: IPluralRule
) => {
    const rule = new Intl.PluralRules('pl').select(value)
    const variation = variations[rule] ?? ''
    return textPattern(value, variation)
}
