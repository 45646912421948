<template>
    <RouterView />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { setDocumentTitle } from './router/utils'
import { IRouteTitle } from './models'

export default defineComponent({
    name: 'App',

    watch: {
        '$route.name'() {
            this.setDocumentTitle()
        },
        '$intl.locale'() {
            this.setDocumentTitle()
        },
    },
    methods: {
        setDocumentTitle() {
            setDocumentTitle((this.$route?.meta?.title as IRouteTitle)(this.$intl))
        },
    },
})
</script>

<style lang="sass">
@import "@/styles/main"
</style>
