import { pluralize } from '@/utils'
import { ILocales } from '../models'
import { LANGUAGE_FALLBACK } from '@/constants'

export const localesEnUs: ILocales = {
    common: {
        add: 'Add',
        delete: 'Delete',
        edit: 'Edit',
        save: 'Save',
        import: 'Import',
        statuses: {
            zero: 'No statuses',
            one: 'Status',
            many: 'Statuses',
        },
        unknown: 'Unknown',
        none: 'None',
        close: 'Close',
        upload: 'Upload',
        accept: 'Accept',
        cancel: 'Cancel',
        areYouSure: 'Are you sure?',
        search: 'Search',
        hotels: {
            zero: 'No hotels',
            one: 'Hotel',
            many: 'Hotels',
        },
        rooms: {
            zero: 'No rooms',
            one: 'Room',
            many: 'Rooms',
        },
        results: {
            zero: 'No results',
            one: 'Result',
            many: 'Results',
        },
        active: 'Active',
        data: {
            zero: 'No data',
            one: 'Data',
            many: 'Data',
        },
        inactive: 'Inactive',
        activate: 'Activate',
        deactivate: 'Deactivate',
        deactivatesIn: (time) => `Deactivates in ${time}`,
        default: 'Default',
        versions: {
            zero: 'No versions',
            one: 'Version',
            many: 'Versions',
        },
        makeDefault: 'Make default',
        deleteDescription:
            'If an item is deleted, it will no longer be visible to the system, and its links to other items will no longer exist.',
        creationDate: 'Creation date',
        deactivationDate: 'Deactivation date',
        period: 'Period',
        details: 'Details',
    },
    validation: {
        required: 'The field is required',
        minLength: (length) =>
            pluralize(
                length,
                (length, variation) =>
                    `The field must contain at least ${length} ${variation}.`,
                {
                    one: 'character',
                    many: 'characters',
                }
            ),
        maxLength: (length) =>
            pluralize(
                length,
                (length, variation) =>
                    `The field cannot contain more than ${length} ${variation}.`,
                {
                    one: 'character',
                    many: 'characters',
                }
            ),
        emailFormat: 'The email address provided is invalid.',
        phoneFormat: 'The number provided is incorrect.',
        checkRequired: 'Checking this box is required.',
        numeric: 'The value must be a number.',
        minValue: (value) => `The value cannot be less than ${value}.`,
        maxValue: (value) => `The value cannot be greater than ${value}.`,
        minDate: (value) => `The date cannot be less than ${value}.`,
        maxDate: (value) => `The date cannot be greater than ${value}.`,
        sameAs: (fieldName) => `The field must have the same value as '${fieldName}'.`,
        listNotEmpty: 'You must select at least one option.',
        maxFileSize: (sizeInMB) => `The maximum permissible file size is ${sizeInMB}MB`,
        fallbackTranslation: `This field must have at least "${LANGUAGE_FALLBACK}" translation`,
    },
    auth: {
        hotelContextChanged: 'Przełączono hotel',
    },
    channels: {
        channels: {
            zero: 'No channels',
            one: 'Channel',
            many: 'Channels',
        },
        order: 'Order',
        orderMode: {
            shift: 'Shifting',
            replace: 'Replacing',
        },
        saveOrder: 'Save order',
        orderSaved: 'Channels order has been saved',
        changeOrder: 'Change Order',
        name: 'Name',
        groups: {
            zero: 'No channel groups',
            one: 'Channel group',
            many: 'Channels groups',
        },
        channelNumber: 'Channel number',
        autoSort: 'Auto Sorting',
        frequency: 'Frequency',
        broadcastType: {
            title: 'Broadcast type',
            options: {
                unknown: 'Unknown',
                terrestrial: 'Terrestrial',
                terrestrial2: 'Terrestrial 2',
                satellite: 'Satellite',
                satellite2: 'Satellite 2',
                cable: 'Cable',
                cablestd: 'Cable std',
                cablehrc: 'Cable hrc',
                cableirc: 'Cable irc',
                analogpalbg: 'Analog pal bg',
                analogpaldk: 'Analog pal dk',
                analogpali: 'Analog pal i',
                analogpalm: 'Analog pal m',
                analogpaln: 'Analog pal n',
                analogsecambg: 'Analog secam bg',
                analogsecamdk: 'Analog secam dk',
                analogsecaml: 'Analog secam l',
                analogntsc: 'Analog ntsc',
                terrestrialatsc3: 'Terrestrial atsc3',
                cableatsc3: 'Cable atsc3',
            },
        },
        programNumber: 'SVCID',
        type: {
            title: 'Type',
            options: {
                rf: 'RF',
                rf_data: 'RF data',
                ip: 'IP',
                ip_data: 'IP data',
                unknown: 'Unknown',
            },
        },
        kind: {
            title: 'Kind',
            options: {
                radio: 'Radio',
                tv: 'TV',
                unknown: 'Unknown',
            },
        },
        response: {
            created: 'Channel has been created',
            updated: 'Channel has been updated',
            deleted: 'Channel has been deleted',
        },
        channelsUploadDescription: 'Upload the new channel list to manage their properties',
        summary: 'The new channel list contains:',
        channelsInstallationStatus: 'Channel installation status',
        epg: 'EPG',
    },
    hotelModes: {
        hotelModes: {
            zero: 'No hotel mode',
            one: 'Hotel mode',
            many: 'Hotel modes',
        },
        volume: {
            max: 'Maximum volume',
            min: 'Minimum volume',
            start: 'Initiation volume',
        },
        response: {
            created: 'Hotel mode has been created',
            updated: 'Hotel mode has been updated',
        },
    },
    devices: {
        clientVersion: 'Client version',
        updateTll: 'Update .tll file',
        notResponding: 'The device is not responding',
        awaitingConnection: 'Awaiting connection',
        offline: 'Offline',
        online: 'Online',
        registerDevice: 'Register device',
        deviceModel: 'Device model',
        manufacturer: 'Manufacturer',
        editDevice: 'Edit device',
        deviceId: 'Device ID',
        serialNumber: 'Serial number',
        deviceHasBeenRegistered: 'Device has been registered',
        deviceHasBeenDeleted: 'Device has been deleted',
        deviceHasBeenUpdated: 'Device has been updated',
        devices: {
            zero: 'No devices',
            one: 'Device',
            many: 'Devices',
        },
        deviceServiceModes: {
            zero: 'No service mode',
            one: 'Service mode',
            many: 'Service mode',
        },
        deviceServiceModeOn: 'Activate service mode',
        deviceServiceModeOff: 'Deactivate service mode',
        deviceHasBeenActivated: 'Service mode has been activated',
        deviceHasBeenDeactivated: 'Service mode has been deactivated',
        deviceServiceModeIsActive: 'It will be deactivated in',
        timestampLastToken: 'Last timestamp of the active device',
        serviceModes: {
            zero: 'No service mode',
            one: 'Service mode',
            many: 'Service modes',
        },
        serviceModeActivated: 'Service mode activated',
        serviceModeDeactivated: 'Service mode deactivated',
        modelDoesNotExistInSeries: (model) =>
            `The ${model} device from the .tll file is not assigned to any series. Please use a different file or contact technical support.`,
        modelDoesNotExistInHotel: (model) =>
            `The ${model} device from the .tll file has not been registered in the hotel. Please use another file.`,
        tllDeviceSeries: 'The .tll file will be distributed to the series',
        applyToAllDevices: 'Upload the .tll file to all compatible models',
        incompatibleSeries: 'The .tll file is incompatible with the series',
        numberIncompatibleDevices: 'Number of incompatible devices',
        downloadDevices: 'Download device list',
        channelListHistory: 'Channel list history',
    },
    xmls: {
        tllFiles: {
            zero: 'No Files .tll',
            one: 'File .tll',
            many: 'Files .tll',
        },
        updateStatus: {
            start: 'The device accepted the order',
            fetching: 'Downloading the .tll file',
            fetchError: 'The .tll file failed to download',
            updating: 'Updating the .tll file',
            updateError: 'The .tll file update failed',
            updated: 'The .tll file has been updated',
        },
        distributeTllFile: 'Distribute file .tll',
        deleteTllFile: 'Delete file .tll',
        response: {
            uploaded: 'File .tll has been uploaded',
            deleted: 'File .tll has been deleted',
        },
        validation: {
            firstPart: 'To confirm, type',
            secondPart: 'in the box below',
            description:
                'The confirmation code is also the name of the hotel where the .tll file will be distributed',
        },
        distributeTllFileDescription:
            'Upload the new tll file to update the channels on all compatible devices in the hotel',
        summary: 'The .tll file will be redistributed to all compatible models',
    },
    users: {
        users: {
            zero: 'No users',
            one: 'User',
            many: 'Users',
        },
        logins: {
            zero: 'No logins',
            one: 'Login',
            many: 'Logins',
        },
        signIn: 'Sign in',
        signOut: 'Sign out',
        passwordChange: 'Password change',
        myAccount: 'My account',
        usernames: {
            zero: 'No usernames',
            one: 'Username',
            many: 'Usernames',
        },
        password: {
            zero: 'No password',
            one: 'Password',
            many: 'Passwords',
        },
        repeatPassword: 'Repeat password',
        changePassword: 'Change password',
    },
    technician: {
        technician: 'Technician',
    },
}
