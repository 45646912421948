import { useRequestAuthInterceptor } from '@/models/Backend/interceptors'
import { Api, ApiConfig } from './GenericApi'
import mitt, { Handler } from 'mitt'

export const pushApiTopics = {
    tllUpdateStatus: 'tll_update_status',
    refreshTll: 'refresh_tll',
} as const

type PushApiTopicKey = keyof typeof pushApiTopics
type PushApiTopic = (typeof pushApiTopics)[PushApiTopicKey]

interface MessageBase {
    type: PushApiTopic
}

export interface MessageTllUpdateStatus extends MessageBase {
    type: 'tll_update_status'
    status: 'start' | 'fetching' | 'fetchError' | 'updating' | 'updateError' | 'updated'
    pushToken: string
}

export type Message = MessageTllUpdateStatus

type EventBusEvents = {
    [pushApiTopics.tllUpdateStatus]: MessageTllUpdateStatus
}

export interface MessageRefreshTll extends MessageBase {
    type: 'refresh_tll'
}
export type OutcomingMessage = MessageRefreshTll

export class BackendPush extends Api<string> {
    private token = ''
    private websocket?: WebSocket
    private eventBus = mitt<EventBusEvents>()

    constructor(options: ApiConfig<string> = {}) {
        super({
            ...options,
            baseURL: `${import.meta.env.VITE_APP_PUSH_API_HTTP_PROTOCOL}://${
                import.meta.env.VITE_APP_PUSH_API_URI
            }`,
        })
        useRequestAuthInterceptor(this.instance)
    }

    private createPushToken = async () => {
        const { data } = await this.pushService.createPushTokenApiV1PushTokensPost()
        this.token = data.token
        return this.token
    }

    protected handleMessageIncome = (e: MessageEvent<string>): void => {
        const incommingMessage: Message = JSON.parse(e.data)
        this.eventBus.emit(incommingMessage.type, incommingMessage)
    }

    public connectWebSocket = async () => {
        await this.createPushToken()

        this.websocket = new WebSocket(
            `${import.meta.env.VITE_APP_PUSH_API_WS_PROTOCOL}://${
                import.meta.env.VITE_APP_PUSH_API_URI
            }/push-streams/${this.token}`
        )

        this.websocket.onclose = () => {
            setTimeout(() => {
                this.connectWebSocket()
            }, 2000)
        }
        this.websocket.addEventListener('message', this.handleMessageIncome)
        return this.token
    }

    public disconnectWebSocket = () => {
        if (!this.websocket) return
        this.websocket.close()
        this.websocket.removeEventListener('message', this.handleMessageIncome)
    }

    public subscribePushApiTopic = async (topic: PushApiTopic) => {
        const { data } = await this.pushService.subscribeToTopicsApiV1PushTopicsSubscribedPost({
            topic,
            push_tokens: [this.token],
        })
        return data.success_count > 0
    }

    public unubscribePushApiTopic = async (topic: PushApiTopic) => {
        const { data } =
            await this.pushService.unsubscribeFromTopicsApiV1PushTopicsUnsubscribedPost({
                topic,
                push_tokens: [this.token],
            })
        return data.success_count > 0
    }

    public subscribeTllUpdateStatus = (handler: Handler<EventBusEvents['tll_update_status']>) =>
        this.eventBus.on(pushApiTopics.tllUpdateStatus, handler)

    public unsubscribeTllUpdateStatus = (
        handler: Handler<EventBusEvents['tll_update_status']>
    ) => this.eventBus.off(pushApiTopics.tllUpdateStatus, handler)

    public sendPushToTokens = async (payload: OutcomingMessage, pushTokens: string[]) => {
        const response =
            await this.pushService.sendPushMessageToTokensApiV1PushMessagesSendToTokensRequestsPost(
                {
                    payload: {
                        ...payload,
                        pushToken: this.token,
                    },
                    push_tokens: pushTokens,
                }
            )
        return response.data.sent_count > 0
    }
}
