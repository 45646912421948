import { defineStore } from 'pinia'
import { useResourceStore } from '../factories'
import { IChannel, IChannelListFilters } from '@/models'
import { ServiceChannels } from '@/services'

export const useChannelsStore = defineStore('channels', () => {
    const store = useResourceStore<IChannel, IChannelListFilters, []>(
        ServiceChannels.fetchChannels.bind(ServiceChannels),
        {}
    )

    return store
})
