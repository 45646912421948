import axios, { AxiosRequestConfig } from 'axios'
import { CacheRequestConfig } from 'axios-cache-interceptor'
import { paramsSerializer } from '../utils'
import { cacheOptions, setupCache, getCacheStrategy, CacheStrategy } from './cache'
import {
    useRequestAuthInterceptor,
    useResponseErrorInterceptor,
    useRequestLanguageInterceptor,
} from '../interceptors'

const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: import.meta.env.VITE_APP_BACKEND_URL,
    paramsSerializer,
})
// interceptors are called in LIFO order, cache must be the last request interceptor
const axiosCacheInstance = setupCache(axiosInstance, cacheOptions)

useRequestAuthInterceptor(axiosCacheInstance)
useResponseErrorInterceptor(axiosCacheInstance)
useRequestLanguageInterceptor(axiosCacheInstance)

interface RequestConfigOptions {
    cacheStrategy?: CacheStrategy
}

export abstract class Backend {
    protected get = axiosCacheInstance.get
    protected post = axiosCacheInstance.post
    protected put = axiosCacheInstance.put
    protected patch = axiosCacheInstance.patch
    protected delete = axiosCacheInstance.delete

    protected createRequestConfig(
        { cacheStrategy }: RequestConfigOptions,
        config?: AxiosRequestConfig
    ): CacheRequestConfig {
        return {
            ...config,
            ...(cacheStrategy && { cache: getCacheStrategy(cacheStrategy) }),
        }
    }

    protected async removeCachedEntry(cacheId: string) {
        try {
            await axiosCacheInstance.storage.remove(cacheId)

            return true
        } catch (error) {
            return false
        }
    }
}
