import { IChannelGroup } from '@/models'
import { IRawChannelGroup } from '../models'

export class ConverterChannelGroup {
    constructor() {
        throw new Error('Cannot instantiate using a static class')
    }

    static async fromRaw({ id, name }: IRawChannelGroup): Promise<IChannelGroup> {
        return {
            id,
            name,
        }
    }
}
