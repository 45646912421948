import { ActionTree, MutationTree } from 'vuex'
import { IStateAuth, IUserAccount, IUserAccountCredentials } from '@/models'
import { MUTATION_AUTH, ACTION_AUTH, STORE_MODULE, LOCAL_STORAGE_KEY } from '@/constants'
import { ServiceAuth } from '@/services'
import { defineAbilitiesForAuthenticated, defineAbilitiesForUnauthenticated } from '@/ability'
import { IStoreModule } from '@/models'

const state = (): IStateAuth => ({
    userAccount: undefined,
    refreshAccessTokenPromise: undefined,
})

const mutations: MutationTree<IStateAuth> = {
    [MUTATION_AUTH.SET_USER_ACCOUNT](state, userAccount?: IUserAccount) {
        state.userAccount = userAccount
    },

    [MUTATION_AUTH.SET_REFRESH_ACCESS_TOKEN_PROMISE](state, promise?: Promise<void>) {
        state.refreshAccessTokenPromise = promise
    },
}

const actions: ActionTree<IStateAuth, unknown> = {
    async [ACTION_AUTH.SIGN_IN_FROM_CREDENTIALS](
        { dispatch },
        credentials: IUserAccountCredentials
    ): Promise<void> {
        try {
            const userAccount = await ServiceAuth.fetchUserAccountFromCredentials(credentials)

            await dispatch(ACTION_AUTH.SIGN_IN, userAccount)
        } catch (error) {
            return Promise.reject(error)
        }
    },

    async [ACTION_AUTH.UPDATE_HOTEL_CONTEXT](
        { state, dispatch },
        hotelId: string
    ): Promise<void> {
        const accessToken = state.userAccount?.accessToken
        if (!accessToken) return Promise.reject('No access token')
        try {
            const userAccount = await ServiceAuth.updateHotelContext(hotelId, accessToken)

            await dispatch(ACTION_AUTH.SIGN_IN, userAccount)
        } catch (error) {
            return Promise.reject(error)
        }
    },

    async [ACTION_AUTH.SIGN_IN_FROM_REFRESH_TOKEN](
        { dispatch },
        refreshToken: string
    ): Promise<void> {
        try {
            const userAccount = await ServiceAuth.fetchUserAccountFromToken(refreshToken)

            await dispatch(ACTION_AUTH.SIGN_IN, userAccount)
        } catch (error) {
            return Promise.reject(error)
        }
    },

    async [ACTION_AUTH.REFRESH_ACCESS_TOKEN]({ state, commit, dispatch }): Promise<void> {
        const refreshToken = state.userAccount?.refreshToken

        if (!refreshToken) return
        if (state.refreshAccessTokenPromise) return state.refreshAccessTokenPromise

        try {
            commit(
                MUTATION_AUTH.SET_REFRESH_ACCESS_TOKEN_PROMISE,
                dispatch(ACTION_AUTH.SIGN_IN_FROM_REFRESH_TOKEN, refreshToken)
            )

            await state.refreshAccessTokenPromise
        } catch (error) {
            return Promise.reject(error)
        }

        commit(MUTATION_AUTH.SET_REFRESH_ACCESS_TOKEN_PROMISE, undefined)
    },

    async [ACTION_AUTH.SIGN_IN]({ commit }, userAccount: IUserAccount): Promise<void> {
        commit(MUTATION_AUTH.SET_USER_ACCOUNT, userAccount)
        localStorage.setItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN, userAccount.refreshToken)
        defineAbilitiesForAuthenticated()
    },

    async [ACTION_AUTH.SIGN_OUT]({ state, commit }): Promise<void> {
        if (state.refreshAccessTokenPromise) await state.refreshAccessTokenPromise

        commit(MUTATION_AUTH.SET_USER_ACCOUNT, undefined)
        localStorage.removeItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN)
        defineAbilitiesForUnauthenticated()
    },
}

export const auth: IStoreModule<IStateAuth> = {
    name: STORE_MODULE.AUTH,
    module: {
        namespaced: true,
        state,
        mutations,
        actions,
    },
}
