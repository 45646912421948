import { IUserDetails, IRawUserDetails } from '@/models'

export class ConverterUserDetails {
    constructor() {
        throw new Error('Cannot instatiate using a static class')
    }

    static fromRaw({
        id,
        first_name: firstName,
        last_name: lastName,
        hotel,
        permissions,
        is_super_admin: isSuperAdmin,
    }: IRawUserDetails): IUserDetails {
        return {
            id,
            firstName,
            lastName,
            hotel,
            permissions,
            isSuperAdmin,
        }
    }

    static toRaw({
        id,
        firstName,
        lastName,
        hotel,
        permissions,
        isSuperAdmin,
    }: IUserDetails): IRawUserDetails {
        return {
            id,
            first_name: firstName,
            last_name: lastName,
            hotel,
            permissions,
            is_super_admin: isSuperAdmin,
        }
    }
}
