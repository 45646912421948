export enum ABILITY_ACTION {
    READ = 'Read',
    CREATE = 'Create',
    UPDATE = 'Update',
    DELETE = 'Delete',
}

export enum ABILITY_SUBJECT {
    UNAUTHENTICATED = 'Unauthenticated',
    AUTHENTICATED = 'Authenticated',
}
