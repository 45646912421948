import { REQUEST_LIST_LIMIT } from '@/constants'
import {
    Backend,
    IRequestListParams,
    IRawRequestListResponse,
    IRequestListResponse,
    IChannelListFilters,
    IChannel,
} from '@/models'
import { IRawChannel, IRawChannelListFilters } from './models'
import { ConverterChannel } from './converters'

export const ServiceChannels = new (class ServiceChannels extends Backend {
    private getRawChannelListFilters(
        filters: IChannelListFilters = {}
    ): IRawChannelListFilters {
        const { kind, channelsGroup, query } = filters

        return {
            kind,
            channels_group: channelsGroup?.id,
            query,
        }
    }

    async fetchChannels({
        limit = REQUEST_LIST_LIMIT.DEFAULT,
        offset,
        filters,
    }: IRequestListParams<IChannelListFilters>): Promise<IRequestListResponse<IChannel>> {
        const {
            data: { results, count },
        } = await this.get<IRawRequestListResponse<IRawChannel>>(
            `technician/television/rf/lg/channels/`,
            {
                params: { offset, limit, ...this.getRawChannelListFilters(filters) },
            }
        )

        return {
            items: await Promise.all(results.map(ConverterChannel.fromRaw)),
            hasMoreItems: results.length === limit,
            total: count,
        }
    }

    async fetchChannel(id: string): Promise<IChannel> {
        const { data } = await this.get<IRawChannel>(
            `technician/television/rf/lg/channels/${id}`
        )

        return await ConverterChannel.fromRaw(data)
    }

    async updateChannel(channelId: string, channel: IChannel): Promise<IChannel> {
        const { data } = await this.patch<IRawChannel>(
            `technician/television/rf/lg/channels/${channelId}`,
            ConverterChannel.toRaw(channel)
        )

        return ConverterChannel.fromRaw(data)
    }

    async createChannel(channel: IChannel): Promise<IChannel> {
        const { data } = await this.post<IRawChannel>(
            `technician/television/rf/lg/channels`,
            ConverterChannel.toRaw(channel)
        )

        return ConverterChannel.fromRaw(data)
    }

    async deleteChannel(id: string): Promise<void> {
        await this.delete(`technician/television/rf/lg/channels/${id}`)
    }

    async importChannels(file: File): Promise<IChannel[]> {
        const formData = new FormData()
        const xmlBlob = new Blob([file], { type: 'application/xml' })
        const fileName = file.name

        formData.append('file', xmlBlob, fileName)

        const { data } = await this.post<IRawChannel[]>(
            `technician/television/rf/lg/channels/file/parsing-results`,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
            }
        )

        return await Promise.all(data.map(ConverterChannel.fromRaw))
    }

    async uploadChannels(channels: IChannel[]): Promise<IChannel[]> {
        const { data } = await this.put<IRawChannel[]>(
            `technician/television/rf/lg/channels/all`,

            channels.map(ConverterChannel.toRaw)
        )

        return await Promise.all(data.map(ConverterChannel.fromRaw))
    }
})()
