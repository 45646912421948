import {
    ABILITY_ACTION,
    ABILITY_SUBJECT,
    ACTION_AUTH,
    LOCAL_STORAGE_KEY,
    ROUTE,
    STORE_MODULE,
} from '@/constants'
import { ability } from '@/ability'
import { NavigationGuard, RouteLocationNormalized, RouteLocationRaw } from 'vue-router'
import { store } from '@/store'

export const navigationGuard: NavigationGuard = async (to, from, next): Promise<void> => {
    if (!isSignedIn()) await signInFromStoredToken()

    if (!isSignedIn() && isTargetRouteForAuthenticated(to)) {
        return next(getSignInRoute(to))
    }

    if (canNavigateTo(to)) return next()

    return from.name ? next(false) : next({ name: ROUTE.START })
}

function isTargetRouteForAuthenticated(to: RouteLocationNormalized): boolean {
    return to.meta?.abilitySubject !== ABILITY_SUBJECT.UNAUTHENTICATED
}

function isSignedIn(): boolean {
    return ability.can(ABILITY_ACTION.READ, ABILITY_SUBJECT.AUTHENTICATED)
}

function canNavigateTo(to: RouteLocationNormalized): boolean {
    return to.matched.every((route) => {
        const { abilityAction, abilitySubject } = route.meta as {
            abilityAction?: ABILITY_ACTION
            abilitySubject?: ABILITY_SUBJECT
        }

        if (abilityAction === undefined || abilitySubject === undefined) return true

        return ability.can(abilityAction, abilitySubject)
    })
}

async function signInFromStoredToken(): Promise<void> {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN)

    if (!refreshToken) return

    return store
        .dispatch(
            `${STORE_MODULE.AUTH}/${ACTION_AUTH.SIGN_IN_FROM_REFRESH_TOKEN}`,
            refreshToken
        )
        .catch(() => {})
}

function getSignInRoute(to: RouteLocationNormalized): RouteLocationRaw {
    return {
        name: ROUTE.SIGN_IN,
        params: { previousRoutePath: to.fullPath },
    }
}
