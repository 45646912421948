import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'
import { RouteLocation, RouteLocationRaw } from 'vue-router'

function getLocation(to: RouteLocation): RouteLocationRaw {
    const {
        query,
        params: { routeSlug },
    } = to

    switch (routeSlug) {
        case 'register-device':
            return {
                name: ROUTE.DEVICE_REGISTER,
                query: {
                    deviceId: query.deviceId?.toString(),
                    devicePassword: query.devicePassword?.toString(),
                    deviceManufacturer: query.deviceManufacturer?.toString(),
                    deviceModel: query.deviceModel?.toString(),
                    serialNumber: query.serialNumber?.toString(),
                },
            }
        default:
            return { name: ROUTE.START, replace: true }
    }
}

export const routesUrlResolver: IRouteConfig[] = [
    {
        path: '/url-resolver/:routeSlug',
        redirect: (to) => getLocation(to),
    },
]
